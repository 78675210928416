html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}


details[open] > summary {
  margin-bottom: 0px !important;
  color: #1c79a5;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

details[open] {
  margin-top: 10px;
  background-color: #E8E8E8;
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 2px 4px #999;
}

#root > div > div > div.ui.inverted.vertical.labeled.icon.ui.overlay.left.thin.visible.sidebar.menu {
  position: absolute;
  left: 0;
  z-index: 800;
}

#logout-button {
  color: white !important;
}

#root > div > div > div > div > a.active.item {
  border-bottom: none;
}

#root > div > div > div.ui.pointing.secondary.menu {
  background-color: white;
}

#root > div.ui.vertical.labeled.icon.ui.overlay.left.thin.visible.sidebar.menu > a.active.icon.item {
  color: white;
  background-color: #2185d0;
}

#root > div.ui.vertical.labeled.icon.ui.overlay.left.thin.visible.sidebar.menu > div.active.item {
  color: white;
  background-color: #2185d0;
}

#root > div.ui.vertical.labeled.icon.ui.overlay.left.thin.visible.sidebar.menu > div.active.item > div {
  color: white;
}

#root > div.ui.vertical.labeled.icon.ui.overlay.left.thin.visible.sidebar.menu > a.active.icon.item > div {
  color: white;
}

#root > div > div > div > div > div.ui.attached.tabular.menu {
  border-color: black;
  margin-bottom: 10px;
}

#root > div > div > div > div > div.ui.attached.tabular.menu > a.active.item {
  color: #1c79a5;
  border-color: black;
  border-top: none;
}

#root > div > div > div > div > div.ui.attached.tabular.menu > a.item {
  background-color: white;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

#login-button {
  color: white !important;
}

#sidebar-opener {
  color: white !important;
}

#root > div.sc-bdVaJa.tSZvJ > div.sc-bwzfXH.cxcGty > div > div > div.ui.placeholder.segment {
  text-align: center;
}


.wcp_logo{
	margin-bottom: 10px;
	text-align: center;
	padding-bottom: 20px;
	padding-top: 20px;
	background-color: white;
	border-bottom: 2px solid gray;
}

.wcp_header{
	background-color: gray;
	margin-top: 0px;
	color: white;
	padding-top: 5px;
	padding-bottom: 1px;
	text-align: center;
}

.wcp_header h3,h4{
	margin-top: 0px;
	margin-bottom: 4px;
}


.wcp_subheader h6{
	background-color: gray;
	margin-top: 0px;
	margin-bottom: 0px;
	color: white;
	padding: 0px 15px;
}
.wcp_subheader .wcp_last5{
	padding-bottom: 10px;
}

.wcp_container{
	background-color: white;
	z-index: 10;
	height: 90vh;
}

.wcp_content{
	padding: 10px 0px;
	width: 70%;
	margin: auto;
	font-size: 1.5em;
}

.wcp_content p{
	margin: 15px;
}

.wcp_content p.salute{
	margin-bottom: 40px;
}