.cross-mark{
	transition: font-weight 0.5s, color 1s, transform 0.5s;
	position: relative;
	right: -35px;
	top: -3px;
	color: rgb(28,121,165);
}

.cross-mark:hover{
	cursor: pointer;
	transform: rotate(-180deg);
	color: red;
	font-weight: bold;
}

