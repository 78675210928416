.grid{
	width: 100%; 
	margin: 10px auto !important; 
	font-family: Didact Gothic, sans-serif;
	font-weight: bold;
	background-color: #E8E8E8;
}

.grid-row{
	width: 100%; 
	margin: auto;
	background-color: rgb(179, 249, 181);
	padding-top: 2px;
	padding-bottom: 2px;
	height: 50px;
	position: relative;
}

.grid-textarea{
	border: none;
	width: 100%;
	padding: 4px 12px;
}

.grid-textarea:focus{
	outline: none;
}

.grid-file-error{
	padding: 6px;
	border: 1px solid #ff6660;
	background: red;
	border-radius: 4px;
	font-size: 14px;
}

.grid-file{
	padding: 6px;
	border: 1px solid #b3f9b5;
	border-radius: 4px;
}

.Loader {
   position: fixed;
   left: 0px;
   top: 0px;
   width: 100%;
   height: 100%;
   z-index: 9999;
}